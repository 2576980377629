import React, {useState} from "react";
import {Button, Card, Form, Input} from "antd";
import {LockOutlined} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router-dom";
import Cookies from "js-cookie";

export default function CompanyLoginForm() {
    const [isLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let history = useHistory();
    let location = useLocation();
    let {from} = location.state || {from: {pathname: "/"}};

    const submit = (values) => {
        setIsLoading(true);
        let url = `  ${process.env.REACT_APP_API_URL}/companies/login`;
        let data = {
            email: values["email"],
            password: values["password"],
        };
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((res) => {
                setIsLoading(false);
                if (res.status !== 200) {
                    setIsError(true);
                    throw new Error(res.statusText);
                } else {
                    return res.json();
                }
            })
            .then((res) => {
                Cookies.set("company_id", res.id, {expires: 365});
                Cookies.set("token", res.token, {expires: 365});
                Cookies.set("as", "company", {expires: 365});
            })
            .catch((e) => {
                setIsLoading(false);
            })
            .finally(() => {
                history.push(from);
            });
    };

    if (isLoggedIn) {
        history.push(from);
    }
    return (
        <Card className="login-card" style={{textAlign: "center"}}>
            <Form name="normal-login" onFinish={submit}>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Please input the email you used to register your company.",
                        },
                        {
                            type: "email",
                            message: "Please enter a valid email."
                        }
                    ]}
                >

                    <Input
                        style={{width: "100%"}}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{required: true, message: "Please input your password."}]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"/>}
                        placeholder="Password"
                    />
                </Form.Item>
                {isError && (
                    <p style={{color: "red"}}>
                        The email or password provided were incorrect.
                    </p>
                )}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        shape="round"
                        style={{marginTop: 10, marginBottom: 10}}
                        loading={isLoading}
                    >
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
}
