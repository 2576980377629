import React from "react";

import NavbarUnAuth from "./NavbarUnAuth";

import "../css/auth.css";
import CompanyLoginForm from "./CompanyLoginForm";

export default function CompanyAuth() {
    return (
        <div className="content">
            <NavbarUnAuth color="#2A71C3"/>
            <div className="container">
                <CompanyLoginForm/>

            </div>
        </div>
    );
}
