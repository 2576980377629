import React, { Component } from "react";
import { Button, Form, Input, message, Spin, TimePicker } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import moment from "moment";
import { Link, Route } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import { prefixSelector } from "./PhoneNumberInput";
import "../css/company-edit.css"

export default class CompanyEditProfile extends Component {
  state = {
    loading: false,
    initLoading: true,
  };
  formRef = React.createRef();

  componentDidMount() {
    fetch(
      `${process.env.REACT_APP_API_URL}/companies?id=${Cookies.get(
        "company_id"
      )}`,
      {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.formRef.current.setFieldsValue({
          name: res.name,
          description: res.description,
          prefix:
            res.phone_numbers !== undefined
              ? res.phone_numbers[0].slice(0, 4)
              : "",
          phone:
            res.phone_numbers !== undefined
              ? res.phone_numbers[0].slice(4)
              : "",
          email: res.email,
          admin_username: res.admin_username,
          opening_time: new moment(res.opening_time),
          closing_time: new moment(res.closing_time),
        });
      })
      .catch((e) => {
        message.error(`Error: ${e.message}`);
      })
      .finally(() =>
        this.setState({
          initLoading: false,
        })
      );
  }

  render() {
    // const AutoCompleteOption = AutoComplete.Option;

    const onFinish = (values) => {
      this.setState({ loading: true });
      const company = {
        site_id: Cookies.get("site_id"),
        name: values.name,
        description:
          values.description !== undefined ? values.description : null,
        phone_numbers: [`${values.prefix}${values.phone}`],
        email: values.email,
        admin_username: values.admin_username,
        opening_time: values.opening_time,
        closing_time: values.closing_time,
      };
      fetch(
        `${process.env.REACT_APP_API_URL}/companies/${Cookies.get(
          "company_id"
        )}`,
        {
          method: "PUT",
          body: JSON.stringify(company),
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          message.success("Successfully updated.");
          this.formRef.current.setFieldsValue({
            name: res.name,
            description: res.description,
            phone: res.phone_numbers !== undefined ? res.phone_numbers[0] : "",
            email: res.email,
            admin_username: res.admin_username,
            opening_time: new moment(res.opening_time),
            closing_time: new moment(res.closing_time),
          });
        })
        .catch((e) => message.error(`Error: ${e.message}`))
        .finally(() => {
          this.setState({ loading: false });
        });
    };

    return (
      <>
        <div className="company-editor-container">
          <Form
            name="register"
            className="company-editor"
            onFinish={onFinish}
            scrollToFirstError
            style={{ textAlign: "center" }}
            ref={this.formRef}
          >
            <Spin spinning={this.state.initLoading}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the company.",
                  },
                ]}
                extra={
                  <small>
                    <i style={{ color: "red" }}>*&nbsp;</i>
                    Enter the name by which the company is referred to.
                  </small>
                }
              >
                <Input placeholder="Name of the company" />
              </Form.Item>

              <Form.Item
                name="description"
                rules={[
                  {
                    max: 300,
                    message:
                      "The description should not be longer than 300 characters.",
                  },
                ]}
                extra={
                  <small>
                    Enter a short description (max 300 characters) about the
                    company.
                  </small>
                }
              >
                <Input placeholder="Description" />
              </Form.Item>

              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input a phone number.",
                  },
                ]}
                extra={
                  <small>
                    <i style={{ color: "red" }}>*&nbsp;</i>
                    Enter the phone number used by the company.
                  </small>
                }
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  placeholder="Phone number"
                />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid email.",
                  },
                  {
                    required: true,
                    message: "Please input an email.",
                  },
                ]}
                extra={
                  <small>
                    <i style={{ color: "red" }}>*&nbsp;</i>
                    Enter the primary email address the company uses.
                  </small>
                }
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="admin_username"
                rules={[
                  {
                    required: true,
                    message: "Please enter a username.",
                  },
                ]}
                extra={
                  <small>
                    <i style={{ color: "red" }}>*&nbsp;</i>
                    Enter a unique username to be used by the company for
                    logging in.
                  </small>
                }
              >
                <Input placeholder="Admin Username" />
              </Form.Item>

              <Form.Item>
                <Link to="/edit/password">
                  <Button
                    style={{ width: "100%" }}
                    icon={<EyeInvisibleOutlined />}
                  >
                    Change Password
                  </Button>
                </Link>
              </Form.Item>
              <Form.Item
                name="opening_time"
                valuePropName="picked"
                rules={[
                  {
                    required: true,
                    message: "Please input a valid opening time.",
                  },
                ]}
                extra={
                  <small>
                    Enter the time the at which the company starts accepting
                    visitors.
                  </small>
                }
              >
                <TimePicker
                  placeholder={"Pick opening time."}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="closing_time"
                valuePropName="picked"
                rules={[
                  {
                    required: true,
                    message: "Please input a valid closing time.",
                  },
                ]}
                extra={
                  <small>
                    Enter the time the at which the company stops accepting
                    visitors.
                  </small>
                }
              >
                <TimePicker
                  placeholder={"Pick closing time."}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={this.state.loading}
                >
                  Update
                </Button>
              </Form.Item>
            </Spin>
          </Form>
        </div>
        <Route
          path="/edit/password"
          exact
          render={() => (
            <ChangePassword
              id={Cookies.get("company_id")}
              urlPath={`${
                process.env.REACT_APP_API_URL
              }/companies/${Cookies.get("company_id")}/password`}
              source="/edit"
            />
          )}
        />
      </>
    );
  }
}
