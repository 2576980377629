import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import CompanyAuth from "./components/CompanyAuth";
import CompanyHomepage from "./components/CompanyHomePage";
import PrivateCompanyRoute from "./PrivateCompanyRoute";
import PublicRoute from "./PublicRoute";
import "./App.css"


function App() {
  return (
      <Router>
        <Switch>
          <PublicRoute
              path="/login"
              exact
              render={(props) => <CompanyAuth {...props} />}
          />
          <PublicRoute
              path="/"
              exact
              render={(props) => <CompanyAuth {...props} />}
          />
          <PrivateCompanyRoute
              path="/visits"
              render={(props) => <CompanyHomepage {...props} />}
          />
          <PrivateCompanyRoute
              path="/unregistered-user-visits"
              render={(props) => <CompanyHomepage {...props} />}
          />
          <PrivateCompanyRoute
              path="/edit"
              exact
              render={(props) => <CompanyHomepage {...props} />}
          />
          <PrivateCompanyRoute
              path="/edit/password"
              exact
              render={(props) => <CompanyHomepage {...props} />}
          />
          <Route path="*" component={"404"}/>
        </Switch>
      </Router>
  );
}

export default App;
