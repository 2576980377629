import React from "react";
import {Redirect, Route} from "react-router-dom";
import Cookies from "js-cookie";


function PrivateCompanyRoute({component: Component, render, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}, props) =>
                Cookies.get("company_id") !== undefined && Cookies.get("as") === "company" ? (
                    Component ? (
                        <Component {...props} />
                    ) : (
                        render()
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateCompanyRoute;
