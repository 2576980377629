import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  EditOutlined,
  HistoryOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Cookies from "js-cookie";
import { enquireScreen } from "enquire-js";
import logo from "../images/logo.png";

import "../css/company-homepage.css";
import CompanyVisitsPage from "./CompanyVisitsPage";
import CompanyEditProfile from "./CompanyEditProfile";
import CompanyUnregisteredUserVisitsPage from "./CompanyUnregisteredUserVisitsPage";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

export default function CompanyHomePage() {
  const history = useHistory();
  const location = useLocation();

  const [isMobile, setIsMobile] = useState();

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");

  const visitsPage = <CompanyVisitsPage />;

  const unregisteredUserVisitsPage = <CompanyUnregisteredUserVisitsPage />;

  const editProfilePage = <CompanyEditProfile />;

  const logout = () => {
    Cookies.remove("site_id");
    Cookies.remove("company_id");
    Cookies.remove("as");
    history.push("/login");
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  useEffect(() => {
    const getSelectedKey = () => {
      return location.pathname;
    };
    setSelectedKey(getSelectedKey());
  }, [location.pathname, selectedKey]);

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile({ isMobile: !!b });
    });
  }, []);

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        breakpoint="md"
        theme="light"
        style={{ paddingTop: 20 }}
      >
        <img
          alt="logo"
          className="logo"
          style={{
            height: isMobile ? "30px" : " 50px",
            textAlign: isMobile ? "center" : "left",
            marginLeft: "0",
            opacity: " 1",
          }}
          src={logo}
        />
        <Menu
          mode="inline"
          style={{}}
          selectedKeys={selectedKey}
          multiple={false}
        >
          <Menu.Item key="/visits" icon={<HistoryOutlined />}>
            Visits
            <Link to="/visits" />
          </Menu.Item>
          <Menu.Item key="/unregistered-user-visits" icon={<HistoryOutlined />}>
            Unregistered User Visits
            <Link to="/unregistered-user-visits" />
          </Menu.Item>
          <SubMenu key="sub1" icon={<SettingOutlined />} title="Account">
            <Menu.Item key="/edit" icon={<EditOutlined />}>
              Edit Profile
              <Link to="/edit" />
            </Menu.Item>
            <Menu.Item key="log-out" icon={<LogoutOutlined />} onClick={logout}>
              Log out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="company-layout" style={{ background: "#fff" }}>
        <Content className="company-layout-background">
          <div
            className="company-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Switch>
              <Route path="/unregistered-user-visits" exact render={() => unregisteredUserVisitsPage} />
              <Route path="/visits" exact render={() => visitsPage} />
              <Route path="/edit" render={() => editProfilePage} />
              <Route path="/" render={() => <Redirect to="/visits" />} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
