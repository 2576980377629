import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Input,
  List,
  Space,
  Spin,
  Table,
} from "antd";
import Cookies from "js-cookie";
import Highlighter from "react-highlight-words";

import "../css/company-sessions.css";
import Modal from "antd/lib/modal/Modal";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";

export default function CompanyUnregisteredUserVisitsPage() {
  const [initLoading, setInitLoading] = useState(true);
  const [visitSession, setVisitSession] = useState({});
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const getData = async () => {
      fetch(
        `  ${process.env.REACT_APP_API_URL}/companies/${Cookies.get(
          "company_id"
        )}/unregistered-user-visits`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res != null) {
            setInitLoading(false);
            setList(res);
          } else {
            setInitLoading(false);
          }
        });
    };
    getData();
  }, []);

  const showModal = (item) => {
    setVisitSession(item);
    setModalVisible(true);
  };

  const onClose = () => {
    setModalVisible(false);
  };

  let searchInput = null;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : dataIndex === "firstName"
        ? record["name"] !== undefined
          ? record["name"]["first"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : dataIndex === "lastName"
        ? record["name"] !== undefined
          ? record["name"]["last"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : dataIndex === "middleName"
        ? record["name"] !== undefined
          ? record["name"]["middle"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : ""
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : text !== undefined && text !== "" && text !== null ? (
        text
      ) : (
        "-"
      ),
  });

  const columns = [
    {
      title: "First Name",
      dataIndex: ["name", "first"],
      key: "firstName",
      width: "20%",
      sorter: (a, b) => {
        if (a.name !== undefined) {
          if (b.name !== undefined) {
            return a.name.first.localeCompare(b.name.first);
          }
          return true;
        }
        return b.name !== undefined;
      },
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Middle Name",
      dataIndex: ["name", "middle"],
      key: "middleName",
      width: "20%",
      sorter: (a, b) => {
        if (a.name !== undefined) {
          if (b.name !== undefined) {
            return a.name.middle.localeCompare(b.name.middle);
          }
          return true;
        }
        return b.name !== undefined;
      },
      ...getColumnSearchProps("middleName"),

      responsive: ["lg"],
    },
    {
      title: "Last Name",
      dataIndex: ["name", "last"],
      key: "lastName",
      width: "20%",
      sorter: (a, b) => {
        if (a.name !== undefined) {
          if (b.name !== undefined) {
            return a.name.last.localeCompare(b.name.last);
          }
          return true;
        }
        return b.name !== undefined;
      },
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Entered At",
      dataIndex: "start_time",
      key: "start_time",
      sorter: (a, b) => {
        return new Date(a.start_time) > new Date(b.start_time);
      },
      width: "20%",
      ...getColumnSearchProps("start_time"),
      render: (time) => {
        if (time !== undefined) {
          let d = new Date(time);
          return d.toLocaleString();
        } else {
          return "-";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Exited At",
      dataIndex: "end_time",
      key: "end_time",
      width: "20%",
      sorter: (a, b) => {
        return new Date(a.end_time) > new Date(b.end_time);
      },
      ...getColumnSearchProps("end_time"),
      render: (time) => {
        if (time !== undefined) {
          let d = new Date(time);
          return d.toLocaleString();
        } else {
          return "-";
        }
      },
      responsive: ["md"],
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: "20%",
      render: (id) => {
        return (
          <Button
            type="link"
            onClick={() => {
              let item = list.find((item) => item.id === id);
              if (item) {
                showModal();
                setVisitSession(item);
              }
            }}
          >
            <InfoCircleOutlined />
          </Button>
        );
      },
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <>
      <Spin spinning={initLoading} className="visits-container">
        <Table
          columns={columns}
          dataSource={list}
          style={{ overflowX: "scroll", margin: "0" }}
        />
      </Spin>
      <Modal
        className="sessions-drawer"
        footer={null}
        onCancel={onClose}
        closable={true}
        visible={modalVisible}
      >
        <Card
          title={
            visitSession.name
              ? `${visitSession.name.first} ${visitSession.name.middle} ${visitSession.name.last}`
              : ""
          }
          bordered={false}
        >
          <p>{`Phone Number: ${
            visitSession.phone_number !== undefined
              ? visitSession.phone_number
              : "-"
          }`}</p>
          <p>{`Email: ${
            visitSession.email !== undefined ? visitSession.email : "-"
          }`}</p>
          <p>{`Company: ${
            visitSession.company !== undefined ? visitSession.company : "-"
          }`}</p>
          <p>{`Car License Plate: ${
            visitSession.car_license_plate !== undefined
              ? visitSession.car_license_plate
              : "-"
          }`}</p>

          <p>{`Entrance Time: ${
            visitSession.start_time !== undefined
              ? new Date(visitSession.start_time).toLocaleString()
              : "-"
          }`}</p>

          <p>Entrance Scanner's Note: {visitSession.start_note ?? "-"}</p>
          <p>{`Entrance Time: ${
            visitSession.start_time !== undefined
              ? new Date(visitSession.start_time).toLocaleString()
              : "-"
          }`}</p>
          <p>Flagged: {visitSession.flagged ? "Yes" : "No"}</p>

          {visitSession.extras !== null ? (
            <List
              dataSource={visitSession.extras}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.name} description={item.value} />
                </List.Item>
              )}
            />
          ) : (
            <div />
          )}
          <p>Exit Scanner's Note: {visitSession.end_note ?? "-"}</p>
          <p>{`Exit Time: ${
            visitSession.end_time !== undefined
              ? new Date(visitSession.end_time).toLocaleString()
              : "-"
          }`}</p>
          <p style={{ textAlign: "center" }} />
        </Card>
      </Modal>
    </>
  );
}
