import React from "react";
import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";

function PublicRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }, props) =>
        Cookies.get("company_id") !== undefined &&
        Cookies.get("as") === "company" ? (
          <Redirect
            to={{
              pathname: "/visits",
              state: { from: location },
            }}
          />
        ) : Component ? (
          <Component {...props} />
        ) : (
          render()
        )
      }
    />
  );
}

export default PublicRoute;
